import React       from 'react';
import { graphql } from 'gatsby'
import Img         from 'gatsby-image';

import Spacer        from '../components/Spacer';
import BrandsBand    from '../components/BrandsBand';
import Heading       from '../components/Heading';
import Wrapper       from '../components/Wrapper';
import Separator     from '../components/Seperator';
import HeaderImage   from '../components/HeaderImage';
import CallToAction  from '../modules/CallToAction';
import DefaultLayout from '../layouts/Default';
import { findImage } from '../components/_helpers';

class TrauringePage extends React.Component {
  render() {
    return (
      <DefaultLayout location={this.props.location}>
        <HeaderImage>
          <Img fluid={findImage(this.props.data.headerImages, 'wedding-rings').fluid} alt={'Wedding Rings'}/>
        </HeaderImage>
        <Wrapper>
          <Spacer/>
          <Heading>Trauringe</Heading>
          <h2 style={{ textAlign: 'center' }}>Lebenstraumringe</h2>
          <p>
            Unser vielfältiges Angebot an Trauringen lässt keine Wünsche offen. Sie finden bei uns vom glatten
            schlichten
            Trauring in verschiedenen Formen und Oberflächen bis hin zu ausgefallenen individuellen Kreationen ein
            breites
            Angebot nationaler und internationaler Werkstätten. Dabei verfügen wir über die gesamte Palette möglicher
            Materialien.
            Einige Lieferanten zeigen auf ihrer Homepage einen Überblick Ihres Sortiments. Zur Information klicken Sie
            bitte das Logo an. Aber gerade bei Trauringen ist die persönliche Beratung und die Anprobe sehr wichtig.
            Deshalb würden wir uns freuen, Sie in unserem Trauringstudio begrüßen zu dürfen.
          </p>
          <Separator/>
          <h2 style={{ textAlign: 'center' }}>Unsere Trauring-Marken</h2>
          <BrandsBand brands={this.props.data.brands.edges}/>
          <Spacer/>
          <CallToAction/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default TrauringePage;

export const query = graphql`
  query WeddingringsPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/wedding-rings"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
    materials: allItemsXlsxTrauringe {
      group(field: subCategory, limit: 1) {
        edges {
          node {
            itemNumber
            subCategory
            fields {
              subCategoryPath
            }
          }
        }
      }
    }
    brands: allMarkdownRemark(filter: {frontmatter: {type: {eq: "brand"}}}, sort: {fields: [frontmatter___slug], order: ASC}) {
      edges {
        node {
          frontmatter {
            logoPath
            slug
            title
          }
        }
      }
    }
  }
`;
